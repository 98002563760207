/* App.css */

body.light-mode {
  background-color: #ffffff;
  color: #000000;
}

.card-light-mode {
  background-color: #f8f9fa;
  color: #000000;
}

.text-dark-mode {
  color: white !important;
}

.text-light-mode {
  color: black !important;
}

/* Dark mode styles */
body.dark-mode {
  background-color: #1f1f1f;
  color: #ffffff;
}

.card-dark-mode {
  background-color: #333333;
  color: #ffffff;
}

.btn.light-mode {
  background-color: #007bff;
  color: #ffffff;
}

.btn.dark-mode {
  background-color: #444444;
  color: #ffffff;
}

.compo-form {
  display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control-inline {
  display: inline-block;
  width: calc(50% - 5px);
  margin-right: 5px;
}

.form-control-inline:last-child {
  margin-right: 0;
}

.lead-select {
  background-color: green;
  color: white;
}

.back-select {
  background-color: orange;
  color: white;
}

.calc-text {
  white-space: pre-wrap;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
}

.badge {
  font-size: 1rem;
  padding: 0.5em;
  margin: 0.2em;
}

html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container-fluid {
  flex-grow: 1;
}

footer {
  margin-top: auto;
}

/* Pour les navigateurs Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px; /* Bordures arrondies pour le track */
  box-shadow: inset 0 0 5px grey; /* Ajout d'un effet pour les bords */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px; /* Bordures arrondies pour la poignée */
  border: 1px solid transparent; 
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Pour Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  box-shadow: inset 0 0 5px grey;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 1px solid transparent;
  background-clip: content-box;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
