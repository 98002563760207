.cookie-consent-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is on top of other content */
  }
  
  .cookie-consent-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 80%;
  }
  
  .cookie-consent-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .cookie-consent-actions .btn-link {
    text-decoration: underline;
  }
  